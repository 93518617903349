<template>
  <div class="flex-col page" style="padding-bottom:0px;">
    <div class="flex-col header">

      <span class="text_1">求职招聘列表</span>
    </div>
    <div class="flex-col group_2">
      <div class="justify-center">
        <div class="flex-row section_1">
          <img src="../../../assets/jobimg/search.png" class="image_3" />
          <input type="text" placeholder="请输入职位搜索" autocapitalize="off" v-model="searchtxt" @keyup.enter="getJobList"
            style="width: 100%; border: 0; background: 0 0" class="text_2" />
          <!-- <span class="text_2">搜索</span> -->
        </div>
        <div :class=" job_wanted_txt !='' ? 'flex-row section_2 active' : 'flex-row section_2' " @click="job_wanted()">
          <img src="../../../assets/jobimg/job_wanted.png" class="image_4" />
          <span class="text_3">求职</span>
        </div>
        <div :class=" advertiseTxt !='' ? 'flex-row section_2 active' : 'flex-row section_2' " @click="advertise()">
          <img src="../../../assets/jobimg/advertise.png" class="image_4" />
          <span class="text_3">招聘</span>
        </div>
        <div class="flex-row section_2 active" @click="chooseRegion = true;">
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/617a2ca7e4f1450011362b37/62836d1d38bbba00113dc3aa/16527817908790865631.png"
            class="image_4" />
          <span class="text_3">城市</span>
        </div>
      </div>
      <div class="flex-col list">

        <div class="flex-col list-item" :class="item.bg_color=='1'? 'hot' : ''" v-for="item in job_list" :id="item.id">
          <div class="justify-between" @click="toDetail(item.jobid)">
            <div class="flex-col">
              <span class="text_7">{{item.job_title}}</span>
              <div class="flex-row group_6">
                <span class="text_9" v-if="item.type =='2'">{{item.monthly_pay1}}-{{item.monthly_pay2}}元</span>
                <span class="text_11" style="font-weight:bold;">{{item.type =='2' ? '招聘信息' : '求职信息'}}
                  {{jobs_range_arr[item.jobs_range]}} </span>
              </div>
            </div>
            <div class="flex-row section_4">
              <img src="../../../assets/jobimg/apply.png" class="image_8" />
              <span class="text_13" @click="toDetail(item.jobid)">查看</span>
            </div>
          </div>
          <div class="flex-col group_7">
            <div class="justify-between group_8" v-if="item.type =='2'">
              <div class="flex-col text-wrapper"><span class="text_15">{{item.welfare_treatment1}}{{item.welfare_treatment2}}{{item.welfare_treatment3}}{{item.welfare_treatment4}}</span></div>
<!--              <div class="flex-col text-wrapper_1"><span class="text_15">{{item.welfare_treatment2}}</span></div>
              <div class="flex-col text-wrapper_2"><span class="text_15">{{item.welfare_treatment3}}</span></div>
              <div class="flex-col text-wrapper_3"><span class="text_15">{{item.welfare_treatment4}}</span></div> -->
            </div>
            <img src="../../../assets/jobimg/line.png" class="image_10" />
            <div class="justify-between group_9">
              <div class="flex-row">
                <img src="../../../assets/logo.png" class="image_12" />
                <div class="flex-col items-start group_11">
                  <span class="text_23">{{item.hr_name}}</span>
                  <span class="text_25">{{item.job_unit}}</span>
                </div>
              </div>
              <span class="text_27">{{item.work_city}}</span>
            </div>
          </div>
        </div>




      </div>
    </div>
    <div class="flex-col tab-bar">
      <!--      <div class="section_7"></div> -->
      <div class="justify-between group_18">
        <span style="position: relative;text-align: center;" @click="home()">
          <img
            src="https://project-user-resource-1256085488.cos.ap-guangzhou.myqcloud.com/617a2ca7e4f1450011362b37/62836d1d38bbba00113dc3aa/16527817953523541194.png"
            class="image_20" />
          <p class="weui-tabbar__label" style="margin-top: 0px;    font-size: 12px;">首页</p>
        </span>
        <span style="position: relative;text-align: center;" @click="add_job(2)">
          <img
            src="https://project-user-resource-1256085488.cos.ap-guangzhou.myqcloud.com/617a2ca7e4f1450011362b37/62836d1d38bbba00113dc3aa/16527817953527690903.png"
            class="image_20" />
          <p class="weui-tabbar__label" style="margin-top: 0px;    font-size: 12px;">发布招聘</p>
        </span>

        <span style="position: relative;text-align: center;" @click="add_job(1)">
          <img
            src="https://project-user-resource-1256085488.cos.ap-guangzhou.myqcloud.com/617a2ca7e4f1450011362b37/62836d1d38bbba00113dc3aa/16527817953530986615.png"
            class="image_20" />
          <p class="weui-tabbar__label" style="margin-top: 0px;    font-size: 12px;">发布求职</p>
        </span>

        <span style="position: relative;text-align: center;" @click="my_job_list()">
          <img
            src="https://project-user-resource-1256085488.cos.ap-guangzhou.myqcloud.com/617a2ca7e4f1450011362b37/62836d1d38bbba00113dc3aa/16527817957744641573.png"
            class="image_20" />
          <p class="weui-tabbar__label" style="margin-top: 0px;    font-size: 12px;">信息管理</p>
        </span>

        <span style="position: relative;text-align: center;" @click="report()">
          <img
            src="https://project-user-resource-1256085488.cos.ap-guangzhou.myqcloud.com/617a2ca7e4f1450011362b37/62836d1d38bbba00113dc3aa/16527817953549478556.png"
            class="image_20 image_25" />
          <p class="weui-tabbar__label" style="margin-top: 0px;    font-size: 12px;">联系客服</p>
        </span>

      </div>
    </div>

    <van-popup v-model="chooseRegion" round position="bottom">
      <van-cascader v-model="chooseRegion" title="请选择区域" :options="quxianJson" @close="chooseRegion = false;"
        @change="chooseRegionChange" @finish="chooseRegion = false;" />
    </van-popup>

  </div>

</template>

<script>
  import {
    Popup,
    Cascader,
    Toast
  } from "vant";
  import {
    RequestApi
  } from "@/apis/demo.js";
  import Cookies from "js-cookie";
  import {
    getQueryVariable
  } from "@/utils/request";
  import area from "@/utils/4-area.json";
  import wx from 'weixin-js-sdk';
  export default {
    components: {},
    data() {
      return {
        searchtxt: "", //搜索关键字
        advertiseTxt: '1', //招聘的查询条件
        job_wanted_txt: '1', //求职的查询条件
        chooseRegion: false, //本次城市选择具体选中的是哪个城市
        choosedRegion: '', //具体选择的城市地址
        quxianJson: [], ////获取所有4级城市的数据
        job_list: [], //job列表信息
        jobs_range_arr: ['', '全职', '兼职'],
      };
    },
    activated() {
      //this.getWxConfig(); //加载js
    },
    mounted() {
      //判断一下，cookie如果丢失了，那么就重新登录
      if (Cookies.get("kh") == "" || Cookies.get("kh") == null) {
        //如果获取用户信息失败那就直接跳到登录
        this.$router.push("login");
        return;
      }
      this.quxianJson = this.parseArea();
      this.getJobList(); //获取正常状态的招聘信息
      this.getWxConfig(); //加载js
    },
    methods: {
      //给页面注入wxjs
      async getWxConfig() {
        let openurl = location.href.split("#")[0];
        let that = this;
        let uri = this.$global.domain + "/tools/post.ashx";
        var param = {
          act: "getwxconfig",
          openurl: openurl,
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          wx.config({
            debug: false,
            appId: result.appId,
            timestamp: result.timestamp,
            nonceStr: result.nonceStr,
            signature: result.signature,
            jsApiList: [
              'onMenuShareTimeline',
              'onMenuShareAppMessage',
              'onMenuShareQQ',
              'onMenuShareQZone',
              'updateTimelineShareData',
              'updateAppMessageShareData',
            ]
          });
          wx.ready(function() {
            // //console.log("js注册成功");
            //Toast("微信js注册成功");
            var shareData = {
              title: '快乐建家首页', //标题
              desc: '快乐建家,集交友,房屋租售,招聘等一起的平台，您也快来参与吧！', //介绍文字
              link: 'http://joyhome.winwinchina.com/#/select?r_id=' + Cookies.get("kh"), //打开地址,要将分享人的编号加进来
              imgUrl: 'http://peng-mall.5izg.cn/img/logo.364a3941.png' //分享的图片地址
            };
            wx.updateTimelineShareData(shareData); //分享到朋友圈
            wx.updateAppMessageShareData(shareData); //分享给好友
          });
        } else {
          Toast(result.msg);
        }
      },

      job_wanted() {
        //是否查询求职的
        if (this.job_wanted_txt == "") {
          this.job_wanted_txt = "1";
        } else {
          this.job_wanted_txt = "";
        }
        this.getJobList(); //获取正常状态的招聘信息
      },
      advertise() {
        //是否查询招聘的
        if (this.advertiseTxt == "") {
          this.advertiseTxt = "1";
        } else {
          this.advertiseTxt = "";
        }
        this.getJobList(); //获取正常状态的招聘信息
      },
      toDetail(jobid) {
        this.$router.push({
          path: '/job_detail',
          query: {
            'id': jobid
          }
        });

      },
      async getJobList() {
        //获取正常状态的招聘信息
        var that = this;
        var uri = "";
        that.job_list = [];
        uri = this.$global.domain + "/tools/post_job.ashx";
        var param = {
          act: "getJobsList",
          // type: "all",
          searchtxt: that.searchtxt, //输入的搜索内容
          advertise: that.advertiseTxt,
          job_wanted: that.job_wanted_txt,
          // price_begin: that.price_begin, //售价范围开始
          // price_end: that.price_end, //售价范围结束
          // room: that.room,
          // houseyear: that.houseyear,
          // orderby: that.orderby,
          // toward: that.toward,
          work_city: that.choosedRegion, //工作的城市街道
          user: Cookies.get("kh"),
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          let data = result.data;
          if (data.length > 0) {
            for (var i = 0; i < data.length; i++) {
              //分解为数组,
              let list1 = data[i][5].toString().split(',')
              var sData = {
                jobid: data[i][0],
                type: data[i][1],
                job_title: data[i][2],
                monthly_pay1: data[i][3],
                monthly_pay2: data[i][4],
                welfare_treatment: data[i][5], //职务标签
                welfare_treatment1: list1[0],
                welfare_treatment2: list1[1],
                welfare_treatment3: list1[2],
                welfare_treatment4: list1[3],
                jobs_range: data[i][6],
                head_count: data[i][7],
                job_desc: data[i][8],
                education: data[i][9],
                work_years: data[i][10],
                work_city: data[i][11],
                work_place: data[i][12],
                tel: data[i][13],
                edit_time: data[i][14],
                num_applicants: data[i][15],
                num_hits: data[i][16],
                job_unit: data[i][17],
                hr_name: data[i][18],
                bg_color: data[i][20],

                // kh: data[i].kh,
              };
              that.job_list.push(sData);
            }
          }
        } else {
          this.$router.push("login");
        }
      },
      add_job(job_type) {
        //发布新的招聘信息  发布招聘
        this.$router.push({
          path: '/job_edit',
          query: {
            'operation_type': 'add',
            'job_type': job_type //1求职  2招聘
          }
        });
      },
      my_job_list() {
        //发布新的招聘信息
        this.$router.push({
          path: '/my_job',
          query: {

          }
        });
      },
      message(item) {
        //消息中心
        this.$router.push({
          path: '/chat',
          query: {
            'userCode': this.issuer_code
          }
        });
      },
      deal_done(item) {
        //成交
        this.$router.push({
          path: '/deal_done',
          query: {
            'id': this.id
          }
        });
      },
      async like() {
        //喜欢 关注
        let that = this;
        var uri = "";
        uri = this.$global.domain + "/tools/MallSelect.ashx";
        var param = {
          action: "collection2021",
          user: Cookies.get("kh"),
          code: this.id, //
          type: "3", //2是收藏会员，1是收藏商品  3是房产  4是招聘
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          //需要切换收藏图片状态
          if (that.Ilike == 0) {
            that.Ilike = "1";
          } else {
            that.Ilike = "0";
          }
        } else {}
      },
      home(item) {
        //房产租赁的首页
        this.$router.push({
          path: '/select',

        });
      },
      report() {
        //改为调用企业微信客服
        window.location.href = "https://work.weixin.qq.com/kfid/kfc87b5402ee83a9aec";
        //this.$router.push("chat?userCode=service");
      },
      parseArea() {
        //获取所有4级城市的数据,加载到界面
        //console.log(area);
        //console.log(area instanceof Array);
        let areaJs = JSON.parse(JSON.stringify(area));
        //console.log(areaJs instanceof Array);
        let result = areaJs.map(item => {
          return {
            text: item.pro_name,
            value: item.pro_code,
            children: item.pro_cities.map(e => {
              return {
                text: e.city_name,
                value: e.city_code,
                children: e.city_areas.map(val => {
                  return {
                    text: val.area_name,
                    value: val.area_code,
                    children: val.area_streets.map(t => {
                      return {
                        text: t.street_name,
                        value: t.street_code
                      }
                    })
                  }
                })
              }
            })
          }
        })
        return result;
      },
      chooseRegionChange({
        selectedOptions
      }) {
        let areaName = selectedOptions.map((option) => option.text).join('/');
        let areaCode = selectedOptions[selectedOptions.length - 1].value;
        console.log("=====================>" + areaName);
        //  console.log(areaCode);
        this.choosedRegion = areaName; //这个房屋所在的具体省市区县街道
        //this.search1_title =areaName.substring(0,4);
        this.getJobList(); //获取正常状态的房屋信息
      },




    },
  };
</script>

<style scoped lang="css">
  .section_2 {
    margin-left: 0.5rem;
    padding: 0.31rem 0.31rem;
    background-color: #d8d8d8;
    border-radius: 0.25rem;
    height: 1.88rem;
  }

  .list-item {
    padding: 1.38rem 1rem 0.75rem;
    background-color: #ffffff;
    border-radius: 0.5rem;
  }

  .image_20 {
    width: 1.69rem;
    height: 1.69rem;
  }

  .image_4 {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
  }

  .group_7 {
    margin-top: 1.31rem;
  }

  .section_4 {
    margin-right: 0.19rem;
    margin-top: 0.13rem;
    padding: 0.063rem 0.44rem;
    background-color: #f6ad4f;
    border-radius: 0.25rem;
    height: 1.5rem;
    border: solid 0.063rem #36c1bc;
  }

  .group_8 {
    /* padding-bottom: 0.75rem; */
            width: 310px;
  }

  .image_10 {
    width: 19.44rem;
    height: 0.063rem;
  }

  .group_9 {
    padding-top: 0.78rem;
  }

  .text_7 {
    color: #292929;
    font-size: 1rem;
    font-family: 'PingFangSC-Semibold';
    font-weight: 600;
    line-height: 1.38rem;
  }

  .group_6 {
    margin-top: 0.13rem;
    padding-left: 0.063rem;
  }

  .image_8 {
    margin: 0.19rem 0;
    flex-shrink: 0;
    width: 0.88rem;
    height: 0.88rem;
  }

  .text_13 {
    margin-left: 0.25rem;
    color: #ffffff;
    font-size: 0.88rem;
    font-family: 'PingFangSC-Medium';
    font-weight: 500;
    line-height: 1.25rem;
  }

  .text-wrapper {
    padding: 0.38rem 0;
    background-color: #36c1bc1a;
    border-radius: 0.13rem;
  /*  height: 1.5rem; */
  }

  .text-wrapper_1 {
    padding: 0.38rem 0;
    background-color: #36c1bc1a;
    border-radius: 0.13rem;
    height: 1.5rem;
  }

  .text-wrapper_2 {
    padding: 0.38rem 0;
    background-color: #36c1bc1a;
    border-radius: 0.13rem;
    height: 1.5rem;
  }

  .text-wrapper_3 {
    padding: 0.38rem 0;
    background-color: #36c1bc1a;
    border-radius: 0.13rem;
    height: 1.5rem;
  }

  .text_27 {
    align-self: center;
    color: #616161;
    font-size: 0.63rem;
    font-family: 'PingFangSC-Regular';
    line-height: 0.88rem;
    letter-spacing: -0.063rem;
    width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .text_9 {
    color: #939393;
    font-size: 0.75rem;
    font-family: 'PingFangSC-Medium';
    font-weight: 500;
    line-height: 1.06rem;
  }

  .text_11 {
    margin-left: 0.44rem;
    color: #939393;
    font-size: 0.75rem;
    font-family: 'PingFangSC-Medium';
    font-weight: 500;
    line-height: 1.06rem;
  }

  .text_15 {
    margin: 0 0.38rem;
    color: #36c1bc;
    font-size: 0.75rem;
    font-family: 'PingFangSC-Regular';
    line-height: 0.75rem;
      width: 300px;

  }

  .text_17 {
    margin: 0 0.38rem;
    color: #36c1bc;
    font-size: 0.75rem;
    font-family: 'PingFangSC-Regular';
    line-height: 0.75rem;
  }

  .text_19 {
    margin: 0 0.38rem;
    color: #36c1bc;
    font-size: 0.75rem;
    font-family: 'PingFangSC-Regular';
    line-height: 0.75rem;
  }

  .text_21 {
    margin: 0 0.38rem;
    color: #36c1bc;
    font-size: 0.75rem;
    font-family: 'PingFangSC-Regular';
    line-height: 0.75rem;
  }

  .image_12 {
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
  }

  .group_11 {
    margin-left: 0.38rem;
  }

  .text_23 {
    color: #616161;
    font-size: 0.63rem;
    font-family: 'PingFangSC-Regular';
    line-height: 0.88rem;
    letter-spacing: -0.063rem;
  }

  .text_25 {
    color: #36c1bc;
    font-size: 0.5rem;
    font-family: 'PingFangSC-Regular';
    line-height: 0.69rem;
    margin-top: 5px;
        width: 140px;
  }

  .text_3 {
    margin-left: 0.25rem;
    color: #ffffff;
    font-size: 0.88rem;
    font-family: 'PingFangSC-Regular';
    line-height: 1.25rem;
  }

  .page {
    background-color: #f8f8f8ff;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }

  .header {
    padding: 0.63rem 0.88rem 0.75rem 1rem;
    background-color: #36c1bc;
    height: 2.6rem;
  }

  .group_2 {
    padding: 0.5rem 1rem 3.13rem;
    flex: 1 1 auto;
    overflow-y: auto;
  }

  .tab-bar {
    padding: 0.063rem 2.03rem 0;
    background-color: #ffffff;
    border-radius: 1.25rem 1.25rem 0px 0px;
  }

  .group {
    padding-left: 1.13rem;
  }

  .text_1 {
    /*   margin-top: 1.38rem; */
    align-self: flex-start;
    color: #ffffff;
    font-size: 1rem;
    font-family: 'PingFangSC-Semibold';
    font-weight: 600;
    line-height: 1.38rem;
  }

  .list {
    margin-top: 1rem;
  }

  .section_7 {
    margin-left: 0.22rem;
    align-self: flex-start;
    background-color: #444444;
    border-radius: 0px 0px 0.25rem 0.25rem;
    width: 1.25rem;
    height: 0.25rem;
  }

  .group_18 {
    padding: 1.34rem 0 1.66rem;
  }

  .text {
    color: #232323;
    font-size: 0.88rem;
    font-family: 'PingFangSC-Medium';
    font-weight: 500;
    line-height: 1.25rem;
    text-transform: uppercase;
  }

  .group_1 {
    margin: 0.22rem 0 0.25rem;
  }

  .section_1 {
    /* padding: 0.44rem 0.75rem 0.38rem; */
    background-color: #f0f0f0;
    border-radius: 0.25rem;
    height: 1.88rem;
  }

  .list-item:not(:first-of-type) {
    margin-top: 1rem;
  }

  .image_25 {
    margin-right: 0.063rem;
  }

  .image {
    margin-top: 0.094rem;
    width: 1.13rem;
    height: 0.69rem;
  }

  .image_1 {
    margin-left: 0.25rem;
    width: 1rem;
    height: 0.75rem;
  }

  .image_2 {
    margin-left: 0.25rem;
    width: 1.56rem;
    height: 0.72rem;
  }

  .image_3 {
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
  }

  .text_2 {
    margin-left: 0.5rem;
    /* color: #ffffff; */
    font-size: 0.75rem;
    font-family: 'PingFangSC-Regular';
    line-height: 1.06rem;
  }

  .view_14 {
    padding: 0.063rem 0.44rem 0.063rem 0.5rem;
  }

  .view_24 {
    padding: 0.063rem 0.44rem 0.063rem 0.5rem;
  }

  .image_14 {
    margin: 0.22rem 0 0.22rem;
    width: 0.75rem;
    height: 0.81rem;
  }

  .text_32 {
    margin-left: 0.31rem;
  }

  .image_17 {
    margin: 0.22rem 0 0.22rem;
    width: 0.75rem;
    height: 0.81rem;
  }

  .text_43 {
    margin-left: 0.31rem;
  }


  /************************************************************
  ** 请将全局样式拷贝到项目的全局 CSS 文件或者当前页面的顶部 **
  ** 否则页面将无法正常显示                                  **
  ************************************************************/

  html {
    font-size: 16px;
  }

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', 'Microsoft Yahei', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  * {
    box-sizing: border-box;
    flex-shrink: 0;
  }

  #app {
    width: 100vw;
    height: 100vh;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
  }

  .flex-col {
    display: flex;
    flex-direction: column;
  }

  .justify-start {
    display: flex;
    justify-content: flex-start;
  }

  .justify-center {
    display: flex;
    justify-content: center;
  }

  .justify-end {
    display: flex;
    justify-content: flex-end;
  }

  .justify-evenly {
    display: flex;
    justify-content: space-evenly;
  }

  .justify-around {
    display: flex;
    justify-content: space-around;
  }

  .justify-between {
    display: flex;
    justify-content: space-between;
  }

  .items-start {
    display: flex;
    align-items: flex-start;
  }

  .items-center {
    display: flex;
    align-items: center;
  }

  .items-end {
    display: flex;
    align-items: flex-end;
  }

  .active {
    background-color: #36c1bc;
  }

  .hot {
    background-color: #e8e8d5;
  }
</style>
